.iban-form {
    padding: 0px 30px;
}

.iban-form ul {

}

.iban-form ul li {
    margin: 15px 0px;
}