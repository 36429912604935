body {
  background-color: #f5f5f5;
  font-family:  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}


@font-face {
  font-family: HKGrotesk;
  src: url("../fonts/HKGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: HKGroteskMedium;
  src: url("../fonts/HKGrotesk-Medium.otf") format("opentype");
}


.clear {
  clear: both;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.blue {
  color: #0176FF;
}

.green {
  color: #00e676;
}

.ta-green {
  color: #00a680;
}

.shadowed {
  -webkit-box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
  box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
}

.card {
  background-color: white;
  border-radius: .35em;
  border: none !important;
  -webkit-box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
  box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, .03), 0 0.9375rem 1.40625rem rgba(31, 10, 6, .03), 0 0.25rem 0.53125rem rgba(31, 10, 6, .05), 0 0.125rem 0.1875rem rgba(31, 10, 6, .03);
}

.container {
  padding: 60px;
}

.card-header {
  border-top-left-radius: .35em !important;
  border-top-right-radius: .35em !important;
  background-color :white;
  color: #0176FF;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  border-bottom: none;
}

.sub {
  color: #777;
  font-size: 0.8em;
}

.indented {
  margin-left: 2em;
}

.hidden {
  display: none;
}

.lead {
  font-weight: 300;
  color: #777;
}

button.MuiButton-containedSecondary {
  color: white;
}

button.form-submit {
  height: 50px;
  font-size: 17px !important;
}

.shape-wrapper {
  border-radius: 0 0 210% 210% / 30%;
  position: relative;
}

.shape {
  position: absolute;
}

.shape-top {
  top: -.75px;
  right: -.75px;
  left: -.75px;
}

.shape-up {
  margin-top: -450px;
}

.shape-wrapper .content {
  text-align: center;
  width: 100%;
  color: white;
}

@media (min-width: 768px) {
  .pt-md-11,
  .py-md-11 {
      padding-top: 6rem !important;
  }
  .pb-md-15,
  .py-md-15 {
      padding-bottom: 22rem !important;
  }
  .main-pricing {
      margin-top: -15rem !important;
  }
}

@media (max-width: 767px) {
  .pt-md-11,
  .py-md-11 {
      padding-top: 6rem !important;
      padding-left: 30px;
      padding-right: 30px;
  }
  .pb-md-15,
  .py-md-15 {
      padding-bottom: 16rem !important;
  }
  .main-pricing {
      margin-top: -15rem !important;
  }
}

