.gmaps_autocomplete_map-maker-icon {
  color: #FD6C6C;
}

.gmaps_autocomplete_suggestion-icon {
  margin-right: 8px;
}

.gmaps_autocomplete_search-bar-container {
  width: 100%;
  max-width: 500px;
  margin: 10px 0px;
}

.gmaps_autocomplete_search-input-container {
  position: relative;
}

.gmaps_autocomplete_search-input,
.gmaps_autocomplete_search-input:focus,
.gmaps_autocomplete_search-input:active {
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: block;
  border-width: 1px;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.gmaps_autocomplete_clear-button,
.gmaps_autocomplete_clear-button:active,
.gmaps_autocomplete_clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.gmaps_autocomplete_autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.gmaps_autocomplete_suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.gmaps_autocomplete_suggestion-item--active {
  background-color: #fafafa;
}

.gmaps_autocomplete_dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.gmaps_autocomplete_dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.gmaps_autocomplete_spinner {
  color: #18bc9c;
  font-size: 30px;
}

.gmaps_autocomplete_error-message {
  color: red;
}