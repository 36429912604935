.validated .MuiFormLabel-root {
    color : #0176FF !important;
    font-weight: bold !important;
}

.contrat-form {
    padding: 20px;
}

.contrat-form hr {
    margin: 30px 0px;
}

.contrat-form .row {
    margin-bottom: 15px;
}


.section-title {
    margin-bottom: 20px;
}

.billdiff-wrapper .MuiFormControlLabel-label {
    font-size: 1em;
}

.billdiff-wrapper label {
    margin-bottom: 0px !important;
}

.contrat-form #map {
    height: 250px;
}

.contrat-form .form-submit {
    width: 248px;
    display: block;
    margin: auto;
}