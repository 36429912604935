.sidebar .card-header {
    color: black !important;
}

.price-block {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price-block .price {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 10px;
    color: #0176FF;
    font-size: 17px;
    font-family: HKGroteskMedium;
}

.price-block .label {
    text-transform: uppercase;
}

.block-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}

.block-items .block {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 8px;
    min-height: 130px;
}

.block-items .block .icon {
    font-size: 30px;
    margin: 8px;
}

.block-items .block.first {
    border-right: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
}
.block-items .block.second {
    border-bottom: 1px solid #f7f7f7;
    border-left: 1px solid #f7f7f7;
}
.block-items .block.third {
    border-top: 1px solid #f7f7f7;
    border-right: 1px solid #f7f7f7;
}
.block-items .block.fourth {
    border-left: 1px solid #f7f7f7;
    border-top: 1px solid #f7f7f7;
}

