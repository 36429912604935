.top-stepper {
    background-color: white;
}

.top-stepper .row > div {
    padding: 0px;
}

.top-stepper img.logo {
    max-width: 200px;
}

.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
